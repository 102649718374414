var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"global-nav-container"},[_c('Button',{staticClass:"btn-toggle-global-nav p-button-text",attrs:{"icon":_vm.collapseButtonIconString},on:{"click":function($event){_vm.isGlobalNavCollapsed = !_vm.isGlobalNavCollapsed}}}),_c('router-link',{staticClass:"logo-link",attrs:{"to":"/plans"}},[_c('img',{staticClass:"global-logo",attrs:{"alt":"Annum logo","src":require("@/assets/logo-global.jpeg"),"width":"126","height":"54"}})]),_c('div',{staticClass:"nav-core"},[_c('router-link',{class:{'router-link-active': _vm.isInPlansSection},attrs:{"to":"/plans"}},[_vm._v("Plans")])],1),_c('div',{staticClass:"global-nav-legal"},[_c('div',{staticClass:"global-help-menu-container"},[_c('a',{staticClass:"p-button-text global-help-menu-button",staticStyle:{"font-size":"1.1rem","padding":"0.8rem 0"},attrs:{"href":"https://www.annumplanning.com/customer-referral-program/","target":"_blank"}},[_vm._v("Referral Program")]),_c('Button',{staticClass:"p-button-text global-help-menu-button",attrs:{"label":"Help"},on:{"click":(event) => {
            this.$refs.globalHelpMenu.toggle(event)
          }}}),_c('OverlayPanel',{ref:"globalHelpMenu",staticClass:"global-help-menu",attrs:{"appendTo":"body"}},[_c('ul',[_c('li',[_c('a',{staticClass:"menu-item",attrs:{"href":"https://www.annumplanning.com/marketing-resources/guides/","target":"_blank"}},[_vm._v("Guides")])]),_c('li',[_c('a',{staticClass:"menu-item",on:{"click":function($event){return _vm.$store.dispatch('updateFeedbackModal', true)}}},[_vm._v("Submit Feedback")])]),_c('li',[_c('a',{staticClass:"menu-item",attrs:{"href":"mailto:hello@annumplanning.com"}},[_vm._v("Contact Us")])])])])],1),_c('div',{staticClass:"global-settings-menu-container"},[_c('Button',{staticClass:"p-button-rounded global-settings-menu-button",attrs:{"label":_vm.$store.getters.currentUser.initials},on:{"click":(event) => {
            this.$refs.globalSettingsMenu.toggle(event)
          }}}),_c('OverlayPanel',{ref:"globalSettingsMenu",staticClass:"global-settings-menu",attrs:{"appendTo":"body"}},[_c('ul',[_c('li',[(_vm.$store.getters.allAccounts.length > 1)?[_c('a',{staticClass:"menu-item",on:{"click":(event) => {
                    this.$refs.selectAccountOverlay.toggle(event)
                  }}},[_vm._v("Account: "+_vm._s(_vm.$store.getters.currentAccount.contactCompanyName))]),_c('OverlayPanel',{ref:"selectAccountOverlay",attrs:{"appendTo":"body"}},_vm._l((_vm.$store.getters.allAccounts),function(account){return _c('div',{key:account.id.intID,staticClass:"p-d-flex p-ai-center p-jc-between p-mb-2"},[_c('h4',{staticClass:"p-mr-3",staticStyle:{"margin-bottom":"0"}},[_vm._v(" "+_vm._s(account.contactCompanyName == '' ? 'Account ' + account.id.intID : account.contactCompanyName)+" ")]),(
                      account.id.intID !==
                      _vm.$store.getters.currentAccount.id.intID
                    )?_c('Button',{attrs:{"icon":"pi pi-arrow-right"},on:{"click":function($event){return _vm.clickSwitchAccount(account)}}}):_vm._e()],1)}),0)]:[_c('span',{staticClass:"menu-item"},[_vm._v("Account: "+_vm._s(_vm.$store.getters.currentAccount.contactCompanyName))])]],2),(this.$store.getters.currentAccount.isActive)?_c('li',[(this.canUserAccessAccountAdminView)?_c('a',{staticClass:"menu-item",on:{"click":(event) => {
                  _vm.$router.push('/account')
                  this.$refs.globalSettingsMenu.hide(event)
                }}},[_vm._v("Account Settings")]):_vm._e()]):_vm._e(),(
              _vm.canUserAccessAccountAdminView &&
              this.$store.getters.currentAccount.showChargebeeUI
            )?_c('li',[_c('a',{staticClass:"menu-item",on:{"click":_vm.clickSubscriptionButton}},[_vm._v("Subscriptions")])]):_vm._e(),(this.$store.getters.currentAccount.isActive)?_c('li',[_c('a',{staticClass:"menu-item",on:{"click":(event) => {
                  _vm.$router.push(
                    '/account/user/' + _vm.$store.getters.currentUser.id.intID
                  )
                  this.$refs.globalSettingsMenu.hide(event)
                }}},[_vm._v("Profile")])]):_vm._e(),_c('li',[_c('a',{staticClass:"menu-item",on:{"click":_vm.clickLogOut}},[_vm._v("Log Out")])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }